<template>
  <div>
      <Brand/>
  </div>
</template>

<script>
  import Brand from './components/Brand'
  export default {
    components: {
      Brand,
    },
    data() {
      return {
      }
    },
  }
</script>
